import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Stack,
  StackDivider,
  StackProps,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next';

export const StepThree = (props: StackProps) => {
  const { t } = useTranslation();

  return (
    <Stack spacing="8" width={"1000px"}>
      <Card boxShadow={'none'}>
        <CardHeader padding={'0'} paddingBottom={"30px"}>
          <Heading className='step-headline-text'>{t("rate_my.conditions_of_participation")}</Heading>
        </CardHeader>

        <CardBody padding={"0"}>
          <Stack divider={<StackDivider style={{margin:0}} />} spacing='4' padding={"0"} gap={"20px"}>
            <Box className='list-item-box' padding={"0 24px"}>
              <Heading size='xs' >
                {t("rate_my.conflict_of_interest")}
              </Heading>
              <Text pt='2' fontSize='sm' padding={"0"}>
                {t("rate_my.confirm_participate_in_our_survey")}
              </Text>
            </Box>
            <Box className='list-item-box' padding={"0 24px"}>
              <Heading size='xs'  padding={"0"}>
                {t("rate_my.confirm_agreement")}
              </Heading>
              <Text pt='2' fontSize='sm' padding={"0"}>
                {t("rate_my.confirm_agreement_nda")}
              </Text>
            </Box>
            <Box className='list-item-box' padding={"0 24px"}>
              <Heading size='xs' >
                {t("rate_my.employer_discussion")}
              </Heading>
              <Text pt='2' fontSize='sm'>
                {t("rate_my.confirm_rate_my_surveys")}
              </Text>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  )

}