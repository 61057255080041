// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-left-side {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.heading {
  color: #fff !important;
  /* component-heading-large/3xl */
  font-family: "Inter";
  font-size: 60px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 100% !important;
}

.sub-text {
  color: #e6fffa !important;
  /* text-lg/lineHeight-7/font-normal */
  font-family: "Inter";
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/login/Login.scss"],"names":[],"mappings":"AAAA;EACE,4BAAA;EACA,sBAAA;EACA,kCAAA;AACF;;AAEA;EACE,sBAAA;EACA,gCAAA;EACA,oBAAA;EACA,0BAAA;EACA,6BAAA;EACA,2BAAA;EACA,4BAAA;AACF;;AAEA;EACE,yBAAA;EACA,qCAAA;EACA,oBAAA;EACA,0BAAA;EACA,6BAAA;EACA,2BAAA;EACA,4BAAA;AACF","sourcesContent":[".login-left-side {\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center center;\n}\n\n.heading {\n  color: #fff !important;\n  /* component-heading-large/3xl */\n  font-family: \"Inter\";\n  font-size: 60px !important;\n  font-style: normal !important;\n  font-weight: 700 !important;\n  line-height: 100% !important;\n}\n\n.sub-text {\n  color: #e6fffa !important;\n  /* text-lg/lineHeight-7/font-normal */\n  font-family: \"Inter\";\n  font-size: 18px !important;\n  font-style: normal !important;\n  font-weight: 400 !important;\n  line-height: 28px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
