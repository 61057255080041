import { Box, BoxProps, Stack, Text } from '@chakra-ui/react'
import "./Steps.scss"
interface StepProps extends BoxProps {
  title: string
  description: string
  isCompleted: boolean
  isActive: boolean
  currentStepComplete: boolean
}

export const Step = (props: StepProps) => {
  const { title, description, isActive, isCompleted, currentStepComplete, ...boxProps } = props

  return (
    <Box
      flex="1"
      py={{ base: '2', md: '3' }}
      ps={{ base: '3', md: '0' }}
      borderTopWidth={{ base: '0', md: '4px' }}
      borderLeftWidth={{ base: '4px', md: '0' }}
      borderColor={isActive || isCompleted ? '#319795 ' : '#EDF2F7'}
      {...boxProps}
      className={'step-button'}
      cursor={currentStepComplete === true ? "pointer" : isActive ? "pointer" : "not-allowed"}

    >
      <Stack spacing="0.5">
        <Text className="step-title" color={isActive || isCompleted ? "#000" : '#9AA4B2'}>
          {title}
        </Text>
        <Text className="step-sub-title" color={isActive || isCompleted ? "#000" : '#9AA4B2'}>{description}</Text>
      </Stack>
    </Box>
  )
}