import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
// import { isAuthenticated } from '../../utils/auth';
import { isAuthenticated } from 'my-api-client-package';


interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  // If the user is not authenticated, redirect to the login page
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  // Otherwise, render the child component (i.e., the protected page)
  return <>{children}</>;
};

export default ProtectedRoute;
