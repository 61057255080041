// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import store from "./store";
import { setStore } from 'my-api-client-package';
import { Login } from './components/login/Login';
import { Register } from './components/register/Register';
import { StepsContainer } from './components/register/steps/StepsContainer';
import Dashboard from './components/dashboard/Dashboard';
import ProtectedRoute from './components/login/ProtectedRoute'; // Import ProtectedRoute

setStore(store, "rate_my_auth_access");

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                {/* Protecting the Dashboard route */}
                <Route path="/steps-registration" element={
                    <ProtectedRoute>
                        <StepsContainer />
                    </ProtectedRoute>
                } />
                <Route
                    path="/dashboard"
                    element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;
