// actions.js
import { CONSTANTS } from './constants.js';

// Action creator for setting the active page
export const setActivePage = (page) => {
    return {
        type: CONSTANTS.SET_ACTIVE_PAGE,
        payload: page,
    };
};

export const updateUsername = (username) => ({
    type: CONSTANTS.USERNAME,
    payload: { username },
});
export const updateCV = (shortCV) => ({
    type: CONSTANTS.UPDATE_CV,
    payload: { shortCV },
});
export const updateProfilePicture = (profilePicture) => ({
    type: CONSTANTS.UPDATE_PROFILE_PICTURE,
    payload: { profilePicture },
});
export const updateCurrentStep = (currentStep) => ({
    type: CONSTANTS.CURRNET_STEP_COMPLETE,
    payload: { currentStep },
});
export const updateEduLevel = (eduLevel) => ({
    type: CONSTANTS.EDU_LEVEL,
    payload: { eduLevel },
});
export const updateSpecialization = (specialization) => ({
    type: CONSTANTS.SPECIALIZATION,
    payload: { specialization },
});
export const updateSpecialistArea = (specialistArea) => ({
    type: CONSTANTS.SPECIALIST_AREA,
    payload: { specialistArea },
});
export const updateLanguage = (language) => ({
    type: CONSTANTS.LANGUAGE,
    payload: { language },
});