import { Avatar, Box, Flex, HStack } from '@chakra-ui/react';
import './Dashboard.scss';
import { Sidebar } from './Sidebar';
import logo from "../../assets/img/logo_mediMESH_only_small.svg";
import { Studies } from './pages/Studies';
import { History } from './pages/History';
import { Payments } from './pages/Payments';
import { Profile } from './pages/Profile';
import { Help } from './pages/Help';
import { Contact } from './pages/Contact';
import { useSelector } from 'react-redux'
import { useEffect } from 'react';
import { requestRefreshToken, getAuthAccessObject } from 'my-api-client-package';

import { useDispatch } from 'react-redux';
import { CONSTANTS } from '../../constants.js';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { loadUserInfo } from '../../helpers/loadUserInfo';

function Dashboard() {

  const { t } = useTranslation();

  const activePage = useSelector((state: any) => state.activePage);
  const userObj = useSelector((state: any) => state.user);
  const token = useSelector((state: any) => state.token);

  const renderActivePage = () => {
    switch (activePage) {
      case 'Studies':
        return <Studies />;
      case 'History':
        return <History />;
      case 'Payments':
        return <Payments />;
      case 'Profile':
        return <Profile />;
      case 'Help':
        return <Help />;
      case 'Contact':
        return <Contact />;
      default:
        return <Studies />;
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    if (getAuthAccessObject().token) {
      dispatch({ type: CONSTANTS.CHANGE_TOKEN, payload: getAuthAccessObject().token })
    }
    const intervalId = setInterval(() => {
      let refreshtoken = requestRefreshToken(navigate);
      refreshtoken.then((resp: any) => dispatch({ type: CONSTANTS.CHANGE_TOKEN, payload: resp.data.message }));
    }, 600000); // Every 10 minutes

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);


  const navigate = useNavigate();
  useEffect(() => {
    if (!userObj.email) loadUserInfo(token, dispatch, navigate);
  }, [token])

  return (
    <Box minH="100vh">
      <HStack flex="1"
        maxW={{ base: 'full' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
        minH={"10vh"}
        boxShadow={"0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px 0px rgba(0, 0, 0, 0.06)"}
        position={"relative"}
        backgroundColor={"#FFF"}
        justifyContent={"space-between"}
      >
        <img src={logo} alt='mediMESH' width={"100px"} />

        {userObj && <Avatar boxSize="10" src={userObj.avatar} />}
      </HStack>
      <Flex as="section" minH="90vh">
        <Sidebar userObj={userObj} />
        {renderActivePage()}
      </Flex>
    </Box>

  );
}

export default Dashboard;