import { Box, Button, Container, Modal, ModalBody, ModalContent, ModalFooter, Stack, useDisclosure } from '@chakra-ui/react'
import { IoArrowForwardOutline } from "react-icons/io5";

import { Step } from './Step'
import { useStep } from './useStep'
import { StepOne } from './StepOne'
import { StepTwo } from './StepTwo'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import bg from "../../../assets/img/main_bg.svg"
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { loadUserInfo } from '../../../helpers/loadUserInfo';
import { useDispatch } from 'react-redux';
import { Api, baseApiParams, getAuthAccessObject, handleApiError } from 'my-api-client-package';
import { CONSTANTS } from '../../../constants';
import { MiscFunctions } from 'helper-functions-package';
import { Notification } from '../../../helpers/Notification';

export const StepsContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state: any) => state.token);
  const userObj = useSelector((state: any) => state.user);
  const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });


  const { t } = useTranslation();

  const steps = [
    {
      title: `${t("rate_my.step")} 1`,
      description: t("rate_my.personal_data"),
    },
    {
      title: `${t("rate_my.step")} 2`,
      description: t("rate_my.education"),
    }
  ]
  const [currentStep, { setStep }] = useStep({ maxStep: steps.length })
  const currentStepComplete = useSelector((state: any) => state.currentStepComplete)

  useEffect(() => {
    if (currentStepComplete) setAlertMessage(null)
  }, [currentStepComplete])

  useEffect(() => {
    if (getAuthAccessObject().token) {
      dispatch({ type: CONSTANTS.CHANGE_TOKEN, payload: getAuthAccessObject().token })
    }
  }, [])

  useEffect(() => {
    if (!userObj.email) loadUserInfo(token, dispatch, navigate);
  }, [token])

  const username = useSelector((state: any) => state.username)
  const shortCV = useSelector((state: any) => state.shortCV)

  const eduLevel = useSelector((state: any) => state.eduLevel)
  const specialistArea = useSelector((state: any) => state.specialistArea)
  const specialization = useSelector((state: any) => state.specialization)

  const profile_picture = useSelector((state: any) => state.profilePicture)


  const [alertMessage, setAlertMessage] = useState<string | null>(null)
  const [alertHead, setAlertHead] = useState<string>('Error')
  const [alertType, setAlertType] = useState<string>('error')

  const handleCompleteRegsiter = () => {
    if (MiscFunctions.isNullOrEmpty(eduLevel) || MiscFunctions.isNullOrEmpty(specialistArea) || MiscFunctions.isNullOrEmpty(specialization)) {
      setAlertMessage("Complete second step.")
    }
    else {
      interface UserObj {
        email: string;
        id: string | any;
        education: string;
        speciality: string;
        specialization: string;
        username?: string;
        shortCv?: string;
        avatar?: string;
      }

      const stepsObj: UserObj = {
        email: userObj.email,
        id: userObj.id,
        education: eduLevel,
        speciality: specialistArea,
        specialization: specialization,
      }
      if (username) stepsObj["username"] = username
      if (shortCV) stepsObj["shortCv"] = shortCV
      if (profile_picture) stepsObj["avatar"] = profile_picture

      try {
        api.user.saveUser(stepsObj, baseApiParams()).then((resp) => {
          console.log(resp)
        })

        navigate("/dashboard")
      }
      catch (err) {
        handleApiError(err, navigate)
      }
    }

  }

  useEffect(() => {
    setAlertMessage(null)
  }, [eduLevel, specialistArea, specialization])

  return (
    <Box background={`url(${bg}) lightgray 50% / cover no-repeat`} height={"100vh"}>
      {alertMessage && <Notification
        heading={alertHead}
        message={alertMessage}
        type={alertType}
        buttonText="Skip"
        onClose={() => setAlertMessage(null)}
      />}

      <Modal isCentered
        onClose={onClose}
        isOpen={true}
        motionPreset='slideInBottom'
        size={'920'}
      >
        <ModalContent width={1000} padding={"40px"} className='modal-steps'>
          <ModalBody padding={"0"} margin={"0"}>
            <Box bg="bg.surface">
              <Container padding={"0"} margin={"0"} className='steps-container' display={"flex"} flexDirection={'column'} gap={15} maxWidth={920}>
                <Stack direction={{ base: 'column', md: 'row' }} gap={"15px"}>

                  {steps.map((step, id) => (
                    <Step
                      key={id}
                      onClick={() => { currentStepComplete === true ? setStep(id) : currentStep === id ? setStep(id) : setAlertMessage("Complete first step") }}
                      title={step.title}
                      description={step.description}
                      isActive={currentStep === id}
                      isCompleted={currentStep > id}
                      currentStepComplete={currentStepComplete}
                    />
                  ))}
                </Stack>

                {currentStep === 0 && <StepOne />}
                {currentStep === 1 && <StepTwo />}

              </Container>
            </Box>
          </ModalBody>

          <ModalFooter justifyContent={"center"} padding={"0"} marginTop={"35px"}>
            <Button backgroundColor='#319795' color="#fff" width={'100%'} className="hover-btn" onClick={() => {
              currentStep === 1 ? handleCompleteRegsiter() : setStep(currentStep + 1)
            }} rightIcon={<IoArrowForwardOutline width={16} height={16} />
            } isDisabled={currentStepComplete ? false : true}>
              {currentStep === 1 ? t("rate_my.complete_registration") : t("rate_my.next")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}