import { Badge, Box, Button, Card, CardBody, CardFooter, CardHeader, Divider, Flex, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalOverlay, SimpleGrid, Spinner, Stack, Text, Tooltip, useDisclosure } from "@chakra-ui/react"
import "./pages.scss"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import moment from 'moment';
import 'moment/locale/de';
import { baseApiParams, Api, StudyDto, handleApiError } from 'my-api-client-package';
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FiArrowRight } from "react-icons/fi"
import { StepThree } from "../../register/steps/StepThree"
import { IoArrowForwardOutline } from "react-icons/io5"
import { useDispatch } from "react-redux"
import { loadUserInfo } from '../../../helpers/loadUserInfo.tsx';

export const Studies = () => {
    const { t } = useTranslation();
    const FRONTEND_APP = process.env.REACT_APP_FRONTEND_URL
    const [studyList, setStudyList] = useState(Object)
    const [spinner, setSpinner] = useState(true)
    const token = useSelector((state: any) => state.token);
    const email = useSelector((state: any) => state.user.email);

    const [selectedStudy, setSelectedStudy] = useState(null);
    const [openConsent, setOpenConsent] = useState(false)
    const navigate = useNavigate();
    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });


    const getAllStudies = async () => {
        if (token) {
            try {
                const study = await api.study.getUserStudies(baseApiParams())
                setStudyList(study.data)
                console.log(study.data)
                setSpinner(false)
            }
            catch (err) {
                handleApiError(err, navigate)
            }
        }
    }

    const handleStudyStart = async (study: StudyDto | null) => {
        try {
            if (study && study.id && study.id.id) {
                await api.study.startStudy(study.id.id, baseApiParams());
                console.log('survey started.')

                if (study.workflowlibrary && study.workflowlibrary.id !== null) {
                    const redirectUrl = `${FRONTEND_APP}/dashboard?_workflow_lib_id=${study.workflowlibrary ? study.workflowlibrary.id : ""
                        }&_token=${token}&_email=${email}&_redirectBy=rate_my`;
                    console.log(redirectUrl)
                    window.open(redirectUrl, "_blank");
                }
                else {
                    console.log('No Workflow!')
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }

    };

    useEffect(() => {
        getAllStudies()
    }, [token])

    const dispatch = useDispatch();

    const { isOpen, onOpen, onClose } = useDisclosure()
    useEffect(() => {
        loadUserInfo(token, dispatch, navigate);
        onOpen()
    }, [])
    return (
        <Stack flex="1"
            maxW={{ base: 'full' }}
            padding={"20px 30px"}
            bg="#eef2f6"
            borderRightWidth="1px"
        >
            {openConsent && <Modal onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent minW={1000} padding={"40px"} className='modal'>
                    <ModalCloseButton />
                    <ModalBody padding={"0"} margin={"0"}>
                        <Box bg="bg.surface">
                            <Stack direction={{ base: 'column', md: 'row' }} width={"100%"} >
                                <StepThree />
                            </Stack>
                        </Box>
                    </ModalBody>
                    <ModalFooter padding={"0"} marginTop={"30px"}>
                        <Button backgroundColor='#319795' color="#fff" width={'100%'} className="hover-btn" onClick={() => {
                            setOpenConsent(false)
                            handleStudyStart(selectedStudy)
                        }} rightIcon={<IoArrowForwardOutline width={16} height={16} />
                        }>
                            {t("rate_my.confirm_continue")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>}

            <Text fontSize='xl' fontWeight='bold' display={"flex"} gap={"12px"} alignItems={"center"} height={"32px"}>
                Rate-my
                <Badge borderRadius={"8px"} minWidth={"25px"} height={"25px"} textAlign={"center"} fontSize='0.8em' backgroundColor={"#319795"} color={"#fff"}>
                    {studyList.length}
                </Badge>
                <Tooltip hasArrow label={t("rate_my.your_upcoming_invitations")} bg='#0C111D' color='#D0D5DD' borderRadius={"8px"} padding={"10px"}>
                    <InfoOutlineIcon color={"#8C8C8C"} />
                </Tooltip>
            </Text>

            <Box>
                <SimpleGrid spacing={4} templateColumns='repeat(auto-fill, minmax(365px, 1fr))'>
                    {spinner && <Spinner
                        thickness='4px'
                        speed='0.90s'
                        emptyColor='gray.200'
                        color='#2C7A7B'
                        size='xl'
                    />}
                    {studyList.length > 0 && studyList.map((study: any, key: number) => {
                        return (
                            <Card padding={"24px"} maxWidth={"365px"} minH={'475px'} maxH={'475px'} justifyContent={"space-between"} key={key} marginBottom={"15px"}>
                                <CardHeader padding={"0"} marginBottom={"15px"}>
                                    <Heading size='md' className="study-heading">{study.label}</Heading>
                                </CardHeader>
                                <CardBody padding={"0"} className="study-text">
                                    <Text>{study.description}</Text>
                                </CardBody>
                                <Divider paddingTop={"10px"} marginBottom={"20px"} borderColor={"#475467"} />
                                <CardFooter display={'flex'} flexDirection={"column"} padding={"0"}>
                                    <Stack>
                                        <Flex justifyContent={"space-between"}>
                                            <Text className="study-footer-detail-label">{t("rate_my.date_of_registration")}:</Text>
                                            <Text className="study-footer-detail-text">{moment(study.dueDate).locale('de').format('dd., D. MMMM YYYY')}</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"}>
                                            <Text className="study-footer-detail-label">{t("rate_my.reward")}:</Text>
                                            <Text className="study-footer-detail-text">{study.bonus}</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"}>
                                            <Text className="study-footer-detail-label">{t("rate_my.time_required")}:</Text>
                                            <Text className="study-footer-detail-text">ca. {study.estimatedTime}</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"}>
                                            <Text className="study-footer-detail-label">{t("rate_my.contact_us")}:</Text>
                                            <Text className="study-footer-detail-text">{study.contact.split(',').length > 1 ? <a href={`mailto:${study.contact.split(',')[1]}`} className="study-contact-link">{study.contact.split(',')[0]}</a> : study.contact}</Text>
                                        </Flex>
                                    </Stack>
                                    <HStack justifyContent={"center"} marginTop={"13px"}>
                                        <Button
                                            variant={"outline"}
                                            size={"md"}
                                            borderColor={"#4BAAAC"}
                                            color={"#4BAAAC"}
                                            width={"100%"}
                                            gap={"8px"}
                                            onClick={() => {
                                                setSelectedStudy(study)
                                                setOpenConsent(true)
                                            }}>{t("rate_my.conduct_a_survey")} <FiArrowRight /> </Button>
                                    </HStack >
                                </CardFooter >
                            </Card >)
                    })}
                </SimpleGrid >
            </Box >

        </Stack >
    )
}