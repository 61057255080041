export const CONSTANTS = {
    PASSWORD: 'PASSWORD',

    USERNAME: 'USERNAME',
    UPDATE_CV: 'UPDATE_CV',
    UPDATE_PROFILE_PICTURE: 'UPDATE_PROFILE_PICTURE',
    CURRNET_STEP_COMPLETE: "CURRNET_STEP_COMPLETE",

    EDU_LEVEL: "EDU_LEVEL",
    SPECIALIZATION: "SPECIALIZATION",
    SPECIALIST_AREA: "SPECIALIST_AREA",

    CHANGE_USER: "USER",
    CHANGE_TOKEN: "TOKEN",
    SIGN_IN: "AUTHENTICATE",
    PASSWORD: "PASSWORD",

    SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',
    
    LANGUAGE: 'LANGUAGE'
}