import {
    Avatar,
    Box,
    Container,
    FormControl,
    FormLabel,
    Input,
    Stack,
    StackProps,
    Textarea,
    WrapItem,
} from '@chakra-ui/react'
import "./Steps.scss"
import { Dropzone } from '../../../helpers/Dropzone'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCurrentStep, updateUsername, updateCV } from '../../../actions'
export const StepOne = (props: StackProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userObj = useSelector((state: any) => state.user);

    const username = useSelector((state: any) => state.username || userObj?.username);
    const ShortCV = useSelector((state: any) => state.shortCV || userObj?.shortCv);

    const handleUser = (e: any) => {
        dispatch(updateUsername(e.target.value));
    };

    const handleShortCV = (e: any) => {
        dispatch(updateCV(e.target.value));
    };

    useEffect(() => {
        if (username !== "") {
            dispatch(updateCurrentStep(true));
        } else {
            dispatch(updateCurrentStep(false));
        }
    }, [username]);

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const handleFilesChange = (files: File[]) => {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target?.result as string); // Set the base64 image URL
            };
            reader.readAsDataURL(files[0]);
        }
    };

    return (
        <Stack spacing="8" {...props} padding={"0"} marginTop={"45px"}>
            <Stack spacing="5" gap={"16px"}>
                <FormControl>
                    <FormLabel htmlFor="user">{t("general.username")}</FormLabel>
                    <Input
                        id="user"
                        type="text"
                        value={username}
                        onChange={handleUser}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel htmlFor="kurzvita">{t("rate_my.short_cv")}</FormLabel>
                    <Textarea
                        id="kurzvita"
                        value={ShortCV}
                        onChange={handleShortCV}
                        placeholder={t("rate_my.write_about_yourself")}
                        className='short-cv'
                    />
                </FormControl>
                <Box as="section" bg="bg.surface" display={'flex'} flexDirection={'column'} gap={"6px"} >
                    <Stack>
                        <span className='upload-image-label'>{t("rate_my.profile_picture")}</span>
                    </Stack>
                    <Container maxW="lg" display={'flex'} flexDirection={'row'} gap={"16px"} maxWidth={'100%'} padding={0}>
                        <WrapItem display={'flex'} flexDirection={'column'} alignItems={'center'}>
                            <Avatar name='Rafi Haidari' size={'xl'} src={imageSrc ? imageSrc : userObj.avatar} />
                        </WrapItem>
                        <FormControl id="file">
                            <Dropzone fileFormats={['JPG', 'PNG']} fileSizeLimit={3} selectedFiles={(e) => handleFilesChange(e)} />
                        </FormControl>
                    </Container>
                </Box>
            </Stack>
        </Stack>
    );
}