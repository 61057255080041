import { Stack } from '@chakra-ui/react';
import { FiGrid, FiHelpCircle, FiMessageSquare } from 'react-icons/fi';
import { IoCalendarOutline, IoCardOutline, IoPersonCircleOutline } from 'react-icons/io5';
import { SidebarButton } from './SidebarButton';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePage } from '../../actions.js';

export const Navigation = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Access activePage from Redux store
    const activePage = useSelector((state: any) => state.activePage);

    // Define the buttons and their props in an array
    const buttons = [
        { label: 'Rate-my', page: 'Studies', icon: FiGrid },
        { label: t('rate_my.history'), page: 'History', icon: IoCalendarOutline },
        { label: t('rate_my.payments'), page: 'Payments', icon: IoCardOutline },
        { label: t('rate_my.profile'), page: 'Profile', icon: IoPersonCircleOutline },
        { label: t('rate_my.help'), page: 'Help', icon: FiHelpCircle },
        { label: t('rate_my.contact_us'), page: 'Contact', icon: FiMessageSquare },
    ];

    const isActive = (page: any) => activePage === page;

    return (
        <Stack spacing="1">
            {buttons.map(({ label, page, icon: Icon }) => (
                <SidebarButton
                    key={page}
                    onClick={() => dispatch(setActivePage(page))}
                    leftIcon={
                        <Icon
                            size="24"
                            color={isActive(page) ? '#E6FFFA' : '#718096'}
                            style={{ transition: 'color 0.2s' }}
                        />
                    }
                    bg={isActive(page) ? '#2C7A7B' : 'transparent'}
                    color={isActive(page) ? '#fff' : '#4A5568'}
                    _hover={{
                        bg: '#2C7A7B',
                        color: '#fff',
                        '& svg': { color: '#E6FFFA !important' }, // Ensure icon color changes on hover
                    }}
                >
                    {label}
                </SidebarButton>
            ))}
        </Stack>
    );
};
