import { CONSTANTS } from '../constants.js';
import { MiscFunctions } from 'helper-functions-package';
import { baseApiParams, Api, handleApiError } from 'my-api-client-package';


export const loadUserInfo = async (token: string, dispatch: any, navigate: any) => {
    const api = new Api({ baseUrl: process.env.REACT_APP_API_URL });

    if (token) {
        try {
            const loggedInUser = await api.user.getCurrentUser(baseApiParams());
            const userData = loggedInUser.data;
            dispatch({ type: CONSTANTS.CHANGE_USER, payload: userData });

            if (MiscFunctions.isNullOrEmpty(userData.speciality) ||
                MiscFunctions.isNullOrEmpty(userData.specialization) ||
                MiscFunctions.isNullOrEmpty(userData.education)) {
                navigate("/steps-registration");
            }
            else {
                navigate("/dashboard");
            }

        } catch (err) {
            handleApiError(err, navigate);
        }
    }
};
