// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.file-formats-text {
  color: #4A5568;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.click-upload-file {
  color: #4A5568;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.hover-btn:hover {
  background-color: rgba(49, 151, 149, 0.8980392157) !important;
}

.notification-block {
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 0px 1px 0px rgba(45, 55, 72, 0.05), 0px 4px 8px 0px rgba(45, 55, 72, 0.1);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/main.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,kLAAA;EAGA,mCAAA;EACA,kCAAA;AADF;;AAIA;EACE,+EAAA;AADF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,cAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,6DAAA;AAFF;;AAKA;EACE,kBAAA;EACA,8HAAA;AAFF","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Inter\", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif !important;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.file-formats-text {\n  color: #4A5568;\n  font-size: 10px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 18px;\n}\n\n.click-upload-file {\n  color: #4A5568;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 24px;\n}\n\n.hover-btn:hover {\n  background-color: #319795e5 !important;\n}\n\n.notification-block {\n  border-radius: 8px;\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25),\n    0px 0px 1px 0px rgba(45, 55, 72, 0.05),\n    0px 4px 8px 0px rgba(45, 55, 72, 0.10);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
