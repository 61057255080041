// store.js
import { createStore } from 'redux';
import { CONSTANTS } from './constants.js';
import { setAuthAccessObject } from 'my-api-client-package';

// Initialer State
const initialState = {
  token: '',
  user: {
    username: '',
    email: '',
  },
  username: "",
  shortCV: "",
  activePage: 'Studies',
  currentStepComplete: false,
  language: "" 
};


// Reducer-Funktion
function reducer(state = initialState, action) {
  switch (action.type) {
    case CONSTANTS.CHANGE_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        }
      };

    case CONSTANTS.CHANGE_TOKEN:
      setAuthAccessObject(JSON.stringify({ token: action.payload }));
      return {
        ...state,
        token: action.payload
      };

    case CONSTANTS.SIGN_IN:
      setAuthAccessObject(JSON.stringify({ token: action.payload }));
      return {
        ...state,
        token: action.payload
      }

    case CONSTANTS.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload
      };

    case CONSTANTS.USERNAME:
      return {
        ...state,
        username: action.payload.username
      };

    case CONSTANTS.UPDATE_CV:
      return {
        ...state,
        shortCV: action.payload.shortCV
      };

    case CONSTANTS.UPDATE_PROFILE_PICTURE:
      return {
        ...state,
        profilePicture: action.payload.profilePicture
      };

    case CONSTANTS.CURRNET_STEP_COMPLETE:
      return {
        ...state,
        currentStepComplete: action.payload.currentStep
      };

    case CONSTANTS.EDU_LEVEL:
      return {
        ...state,
        eduLevel: action.payload.eduLevel
      };

    case CONSTANTS.SPECIALIZATION:
      return {
        ...state,
        specialization: action.payload.specialization
      };

    case CONSTANTS.SPECIALIST_AREA:
      return {
        ...state,
        specialistArea: action.payload.specialistArea
      };

    case CONSTANTS.LANGUAGE:
      return {
        ...state,
        language: action.payload.language
      };

    default:
      return state;
  }
}

// Erstelle den Store
const store = createStore(reducer);
store.subscribe(() => {
  console.log('State wurde geändert:', store.getState());
});

export default store;